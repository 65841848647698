<template>
  <div class="tab-pane active" id="rcs-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12">
          <Chart
            ref="chartStats"
            title="Flows por Status"
            :data="statusFlows"
            :loading="loadingStatus"
            @type="scopeType(...arguments)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <Chart
            ref="chartType"
            title="Flows por Tipo"
            :data="typeFlows"
            :loading="loadingType"
            @type="scopeType(...arguments)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <Chart
            ref="chartChannel"
            title="Flows por Canal"
            :data="channelFlows"
            :loading="loadingChannel"
            @type="scopeType(...arguments)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import Chart from '@/components/rebranding/Chart.vue';

export default {
  name: 'FlowStats',
  components: {
    Chart,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      loadingStatus: true,
      loadingType: true,
      loadingChannel: true,
      statusFlows: [],
      typeFlows: [],
      typeChannels: [],
      channelFlows: [],
      type: 'line',
      inbound: 0,
      outbound: 0,
      failures: 0,
      charged: 0,
    };
  },
  computed: {
    timeWindow() {
      if (moment(this.endDate).diff(moment(this.startDate), 'days')) {
        return 'day';
      }
      return 'hour';
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    scopeType(type) {
      this.type = type;
    },
    fetch() {
      this.fetchStatusMessages(this.filters);
      this.fetchTypeFlows(this.filters);
      this.fetchChannelFlows(this.filters);
    },
    fetchStatusMessages(filter) {
      this.loadingStatus = true;
      this.$store
        .dispatch('fetchFlowInsights', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'status',
          filter,
        })
        .then((response) => {
          this.statusFlows = response.map((insight) => {
            insight.group_by = this.$tc(
              `app.${insight.group_by.toLowerCase()}`,
            );
            return insight;
          });
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
    fetchTypeFlows(filter) {
      this.loadingType = true;
      this.$store
        .dispatch('fetchFlowInsights', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'flow.name',
          filter,
        })
        .then((response) => {
          this.typeFlows = response;
        })
        .finally(() => {
          this.loadingType = false;
        });
    },
    fetchChannelFlows(filter) {
      this.loadingChannel = true;
      this.$store
        .dispatch('fetchFlowInsights', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'channel.name',
          filter,
        })
        .then((response) => {
          this.channelFlows = response.map((insight) => {
            if (insight.group_by.includes('-')) {
              insight.group_by = this.$tc(
                `app.${insight.group_by.toLowerCase()}`,
              );
            }
            return insight;
          });
        })
        .finally(() => {
          this.loadingChannel = false;
        });
    },
    exportData(format, chart) {
      console.log('Format: ', format, '\n', 'Chart: ', chart);
      const chartFormat = this._self.type;
      let chartElm = null;
      switch (chart.label) {
        case 'chartStats':
          chartElm = this._self.$refs.chartStats;
          break;
        case 'chartType':
          chartElm = this._self.$refs.chartType;
          break;
        case 'chartChannel':
          chartElm = this._self.$refs.chartChannel;
          break;
        default:
          chartElm = this._self.$refs.chartStats;
          break;
      }
      switch (format) {
        case 'png':
          console.log('PNG Export:: ');
          if (chartFormat === 'line') {
            chartElm.$refs.lineChart.chart.ctx.exports.exportToPng(chartElm.$refs.lineChart.chart);
          } else {
            chartElm.$refs.pieChart.chart.ctx.exports.exportToPng(chartElm.$refs.pieChart.chart);
          }
          break;
        case 'svg':
          console.log('SVG Export:: ');
          if (chartFormat === 'line') {
            chartElm.$refs.lineChart.chart.ctx.exports.exportToSVG(chartElm.$refs.lineChart.chart);
          } else {
            chartElm.$refs.pieChart.chart.ctx.exports.exportToSVG(chartElm.$refs.pieChart.chart);
          }
          break;
        case 'csv':
          console.log('CSV Export:: ');
          if (chartFormat === 'line') {
            chartElm.$refs.lineChart.chart.ctx.exports.exportToCSV({
              series: chartElm.$refs.lineChart.chart.w.config.series,
              columnDelimiter: ';',
            });
          } else {
            chartElm.$refs.pieChart.chart.ctx.exports.exportToCSV({
              series: chartElm.$refs.pieChart.chart.w.config.series,
              columnDelimiter: ';',
            });
          }
          break;
        default:
          if (chartFormat === 'line') {
            chartElm.$refs.lineChart.chart.ctx.exports.exportToPng(chartElm.$refs.lineChart.chart);
          } else {
            chartElm.$refs.pieChart.chart.ctx.exports.exportToPng(chartElm.$refs.pieChart.chart);
          }
          break;
      }
      console.log('Chart Export Teste: ', chartElm.$refs, '\nSelf: ', this._self);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-column-right {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
}
.card-messages {
  /* margin-left: auto; */
  max-height: 230px;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: var(--clr-light-2);
      font-weight: bold;
    }
  }
}
#infos-bottom,
#infos-top {
  position: relative;
  z-index: 1;
  min-width: 260px;
}
.card-messages.inner-card {
  /* position: absolute;
  right: 0; */
  /* opacity: 0; */
  z-index: 1;
}
.card-messages.inner-card.active {
  /* position: absolute;
  right: 325px; */
  z-index: 0;
  /* opacity: 1; */
}
/* .card-messages.inner-card.active:last-of-type {
  right: 470px;
} */

.darkmode .card {
  background-color: var(--background-3);
}
</style>
